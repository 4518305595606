/* Main Menu Sidebar
------------------------------------
*/

body {
  &.mobile {
    .sidebar-menu {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }
    .sidebar-menu > ul {
      height: auto !important;
      overflow: visible !important;
      -webkit-overflow-scrolling: touch !important;
    }
    .page-sidebar .sidebar-menu .menu-items {
      li {
        &:hover {
          a {
            color: $color-menu-light;
          }
          .icon-thumbnail {
            color: $color-menu-light !important;
          }
        }
        &.active,
        &.open {
          & > a {
            color: #fff;
          }
          & > .icon-thumbnail {
            color: #fff;
          }
        }
      }
    }
    .drager {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  &.sidebar-visible {
    .page-sidebar {
      .scroll-element {
        visibility: visible;
      }
      .menu-items {
        .icon-thumbnail {
          @include translate3d(-14px, 0, 0);
        }
      }
      .sidebar-header .sidebar-header-controls {
        @include translate3d(58px, 0, 0);
      }
    }
  }
}

.page-sidebar {
  width: $layout-sidepanel-width;
  background-color: $color-menu;
  z-index: $zIndex-sidebar;
  @include left($layout-sidepanel-width-collapsed - $layout-sidepanel-width);
  position: fixed;
  bottom: 0;
  top: 0;
  @include right(auto);
  overflow: hidden;
  -webkit-transition: -webkit-transform 400ms cubic-bezier($menu-bezier);
  -moz-transition: -moz-transform 400ms cubic-bezier($menu-bezier);
  -o-transition: -o-transform 400ms cubic-bezier($menu-bezier);
  transition: transform 400ms cubic-bezier($menu-bezier);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  a,
  button {
    color: $color-menu-light;
    &:hover,
    &:active {
      color: $color-menu-link-hover;
      background-color: transparent !important;
      &:focus {
        color: $color-menu-link-hover;
      }
    }
    &:visited,
    &:focus {
      color: $color-menu-light;
    }
  }
  button.sidebar-slide-toggle {
    //BS 4 fixes
    &.active {
      color: $color-menu-light;
      &:focus {
        background: transparent;
      }
    }
  }
  .scroll-element {
    visibility: hidden;
  }
  .sidebar-header {
    /* Side-bar header */
    display: block;
    height: $layout-header-height;
    line-height: $layout-header-height;
    background-color: $color-menu-darker;
    border-bottom: 1px solid mix($color-menu-darker, #000, 90%);
    color: $color-contrast-lowest;
    width: 100%;
    padding: 0 20px;
    @include padding-left(30px);
    clear: both;
    z-index: 10;
    position: relative;
    user-select: none;
    .sidebar-header-controls {
      display: inline-block;
      -webkit-transition: -webkit-transform 0 0.4s cubic-bezier($menu-bezier);
      transition: transform 0 0.4s cubic-bezier($menu-bezier);
      -webkit-backface-visibility: hidden;
      .btn i {
        width: 18px;
        height: 18px;
        margin: 0;
      }
    }
    .sidebar-slide-toggle i {
      @include transition(all 0.12s ease);
    }
    .sidebar-slide-toggle.active i {
      @include rotate(-180deg);
    }
  }
  .close-sidebar {
    position: absolute;
    @include right(19px);
    top: 14px;
    padding: 9px;
    z-index: 1;
    & > i {
      color: rgba(255, 255, 255, 0.57);
    }
  }
  .sidebar-overlay-slide {
    /* Side-bar Top Slider */
    width: 100%;
    height: 100%;
    background-color: $color-menu-darker;
    display: block;
    z-index: 9;
    padding: $layout-header-height + 20 20px 20px 20px;
    &.from-top {
      top: -100%;
      position: absolute;
      @include transition(all 0.2s ease);
      &.show {
        @include translate(0, 100%);
      }
    }
  }
  .sidebar-menu {
    /* Side-bar Menu */
    height: calc(100% - 50px);
    position: relative;
    width: 100%;
    .outer-tab-nav-section {
      display: inline-block;
      width: 45px;
      position: absolute;
      height: 100%;
      background-color: #0aa699 !important;
    }
    .menu-items {
      /* Side-bar Menut Items */
      list-style: none;
      margin: 0;
      padding: 0;
      margin: 0;
      padding: 0;
      position: relative;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(100% - 10px);
      width: 100%;
      li {
        &:hover,
        &.open,
        &.active {
          & > .icon-thumbnail {
            color: $color-menu-link-hover;
          }
          & > a {
            color: $color-menu-link-hover;
          }
        }
        & > a {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 65%;
        }
      }
      & > li {
        display: block;
        padding: 0;
        clear: right;
        &:after,
        &:before {
          display: table;
          content: " ";
          clear: both;
        }

        & > a {
          font-family: $interface-font-family, sans-serif;
          display: inline-block;
          @include padding-left(32px);
          min-height: $menu-icon-size;
          line-height: $menu-icon-size;
          font-size: 14px;
          clear: both;
          &.open {
            background: #313131;
          }
          & > .arrow {
            @include float(right);
            padding-right: 25px;
            &:before {
              @include float(right);
              display: inline;
              font-size: 16px;
              font-family: "pages-icon";
              height: auto;
              content: "\e989";
              font-weight: 300;
              text-shadow: none;
              @include transition(all 0.12s ease);
            }
            &.open {
              &:before {
                @include rotate(-90deg);
              }
            }
          }
          & > .badge {
            margin-top: 12px;
          }
          & > .title {
            @include float(left);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 65%;
          }
          & > .details {
            font-size: 12px;
            opacity: 0.4;
            display: block;
            clear: both;
          }
          &.detailed {
            & > .title {
              line-height: 28px;
            }
            & > .details {
              line-height: 16px;
            }
          }
        }
        &.active {
          & > ul {
            &.sub-menu {
              display: block;
            }
          }
        }
        & > .icon-thumbnail {
          &:first-letter {
            text-transform: uppercase;
          }
        }
        & > ul {
          &.sub-menu {
            & > li {
              ul {
                &.sub-menu {
                  li {
                    @include padding-left(10px);
                    @include padding-right(3px);
                  }
                  .icon-thumbnail {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    font-size: 10px;
                  }
                }
              }
            }
            li {
              & > a {
                & > .arrow {
                  &:before {
                    @include float(right);
                    margin-top: 1px;
                    margin-right: 20px;
                    display: inline;
                    font-size: 16px;
                    font-family: "pages-icon";
                    content: "\e989";
                    font-weight: 300;
                    text-shadow: none;
                    @include transition(all 0.12s ease);
                  }
                  &.open {
                    &:before {
                      @include float(right);
                      margin-top: 1px;
                      margin-right: 18px;
                      display: inline;
                      font-size: 16px;
                      @include rotate(-90deg);
                      font-weight: 300;
                      text-shadow: none;
                    }
                  }
                }
              }
            }
          }
        }
        ul {
          &.sub-menu {
            display: none;
            list-style: none;
            clear: both;
            @include margin(0, 0, 10px, 0);
            background-color: $color-menu-dark;
            padding: 18px 0 10px 0;
            & > li {
              background: none;
              @include padding(0px, 20px, 0, 40px);
              margin-top: 1px;
              &:hover {
                & > .icon-thumbnail {
                  color: $color-menu-link-hover;
                }
              }
              & > a {
                display: inline-block;
                padding: 5px 0px;
                font-size: 13px;
                font-family: $interface-font-family, sans-serif;
                white-space: normal;
              }
              .icon-thumbnail {
                width: 30px;
                height: 30px;
                line-height: 30px;
                margin: 0;
                background-color: $color-menu;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .muted {
      color: $color-hinted-a;
    }
  }
}
[data-toggle-pin="sidebar"] {
  & > i:before {
    content: "\e98c";
  }
}
/* Sidebar icon holder
------------------------------------
*/
.icon-thumbnail {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: $menu-icon-size;
  width: $menu-icon-size;
  line-height: $menu-icon-size;
  text-align: center;
  vertical-align: middle;
  position: relative;
  left: 0;
  @include float(right);
  @include margin-right(14px);
  color: $color-menu-light;
  -webkit-transition: -webkit-transform 0 0.4s cubic-bezier($menu-bezier);
  transition: transform 0 0.4s cubic-bezier($menu-bezier);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: bold;
  & > i {
    font-size: 20px;
  }
  & > svg {
    width: auto;
    height: auto;
    display: inline;
  }
}
/* Sidebar Responsive Classes
------------------------------------
*/
@media only screen and (max-width: 1400px) {
  .page-sidebar .page-sidebar-inner .sidebar-slide {
    .sidebar-menu {
      bottom: 50px;
    }
    .sidebar-widgets {
      display: none;
    }
  }
}

@media only screen and (min-width: 980px) {
  body {
    &.ie9.menu-pin {
      .page-sidebar {
        transform: none !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
      }
    }
    &.menu-pin {
      [data-toggle-pin="sidebar"] {
        & > i:before {
          content: "\e9a2";
        }
      }
      .page-sidebar {
        transform: translate(
          $layout-sidepanel-width - $layout-sidepanel-width-collapsed,
          0
        ) !important;
        -webkit-transform: translate(
          $layout-sidepanel-width - $layout-sidepanel-width-collapsed,
          0
        ) !important;
        -ms-transform: translate(
          $layout-sidepanel-width - $layout-sidepanel-width-collapsed,
          0
        ) !important;
        width: $layout-sidepanel-pin-width;
        .sidebar-header .sidebar-header-controls {
          @include translateX(30px);
        }
        .menu-items .icon-thumbnail {
          @include translate3d(-14px, 0, 0);
        }
      }
    }
    &.menu-behind {
      .page-sidebar {
        z-index: $zIndex-navbar - 1;
      }
    }
    &.box-layout {
      .page-sidebar {
        left: auto;
        transform: none !important;
        -webkit-transform: none !important;
      }
    }
  }
}

@media (max-width: 991px) {
  .page-sidebar {
    width: 250px;
    z-index: auto;
    @include left(0 !important);
    -webkit-transform: translate3d(0, 0px, 0px) !important;
    transform: translate3d(0, 0px, 0px) !important;
    .sidebar-menu .menu-items > li ul.sub-menu > li {
      padding: 0px 30px 0 36px;
    }
  }

  .icon-thumbnail {
    margin-right: 24px;
  }
  .page-sidebar {
    display: none;
    z-index: auto;
    &.visible {
      display: block;
    }
    .page-sidebar-inner {
      z-index: 1;
      left: 0 !important;
      width: 260px;
      .sidebar-slide {
        .sidebar-menu {
          overflow: scroll;
          -webkit-overflow-scrolling: touch;
          top: 40px;
          bottom: 0;
          ul > li > a > .badge {
            display: inline-block;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .page-sidebar .sidebar-menu .menu-items ul {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .page-sidebar .sidebar-menu .menu-items > li {
    & > a {
      padding-left: 27px;
    }
    ul.sub-menu > li {
      padding: 0px 27px 0 31px;
    }
  }
}

@media (max-width: 991px) {
  .sidebar-menu {
    .icon-thumbnail {
      margin-right: 15px;
    }
  }
}

@media (max-width: 767px) {
  .page-sidebar {
    .sidebar-header {
      padding: 0 12px;
    }
    .sidebar-menu .menu-items > li {
      & > a {
        padding-left: 20px;
      }
      ul.sub-menu > li {
        padding: 0px 25px 0 28px;
      }
    }
  }
}

@media (max-width: 480px) {
  body {
    .header {
      .header-inner {
        .toggle-secondary-sidebar {
          font-size: 16px;
          top: 12px;
        }
      }
    }
  }
  .page-sidebar .sidebar-header {
    height: $layout-mobile-header-height;
    line-height: $layout-mobile-header-height;
  }
}
/* END Sidebar
------------------------------------
*/
