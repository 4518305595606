/*------------------------------------------------------------------
[15. Form Elements]
*/
// Mixins
// --------------------------------------------------

// Form validation states
@mixin form-control-validation($text-color, $border-color, $background-color) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $border-color;
    @include box-shadow(none); // Redeclare so transitions work
    &:focus {
      border-color: darken($border-color, 10%);
      @include box-shadow(none);
    }
    //Auto fill
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px $color-contrast-lowest inset,
        0 0 0 1px rgba(0, 0, 0, 0.1), 0 -1px 0 0 rgba(0, 0, 0, 0.05) !important;
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    background: $color-contrast-lower;
    border: 1px solid $color-border-a;
    color: fade($color-contrast-high, 47%);
  }
  // Optional feedback icon
  .form-control-feedback {
    color: $text-color;
  }
}

label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

label {
  font-family: $force-mac-font-family, sans-serif;
  letter-spacing: -0.006em;
}

select[multiple],
select[size] {
  height: auto !important;
}

input:focus,
select:focus,
textarea:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none;
  @include box-shadow(none);
}

//Webskit Auto fill
input {
  &:-webkit-autofill,
  &.error:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $color-contrast-lowest inset !important;
    &:focus {
      -webkit-box-shadow: 0 0 0 1000px $color-contrast-lowest inset !important;
    }
  }
}

form legend {
  @include margin(15px, 0px, 10px, 0px);
}

.form-control {
  background-color: $color-contrast-lowest;
  background-image: none;
  border: 1px solid $color-border-a;
  border-top-color: fade($color-contrast-higher, 21%);
  font-family: $force-mac-font-family, sans-serif;
  -webkit-appearance: none;
  color: $color-contrast-higher;
  outline: 0;
  padding: 6px 8px;
  line-height: 16px;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  min-height: 32px;
  @include transition(all 0.12s ease);
  @include border-radius(2px);
  @include transition(background 0.2s linear 0s);
  @include placeholder(inherit, 0.47);
  &:focus {
    border-color: unset;
    background-color: $color-contrast-lowest;
    border: 1px solid $color-primary;
    box-shadow: none;
    outline: 0 !important;
    @include placeholder(inherit, 0.57);
  }
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background: mix($color-contrast-lower, #fff, 50%);
    color: fade($color-contrast-high, 33%);
    cursor: not-allowed;
  }
  //Textfields and inpt
  &.transparent {
    background-color: transparent;
    border-color: transparent;
    &:focus {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

/* Input Sizes
--------------------------------------------------
*/

.input-sm,
.form-horizontal .form-group-sm .form-control {
  font-size: 13px;
  min-height: 32px;
  padding: 7px 9px;
}

.input-lg,
.form-horizontal .form-group-lg .form-control {
  border-radius: 3px;
  font-size: 18px;
  min-height: 45px;
  padding: 11px 13px;
}

.input-xlg {
  min-height: 51px;
  font-size: 18px;
  line-height: 22px;
}

// With Controls
.input-group-lg > {
  .form-control {
    padding: 6px 1rem;
  }
}

.input-group-sm > {
  .form-control {
    padding: 0 0.5rem;
    min-height: 32px;
  }
}

//Select2 lg
.select2-lg {
  .select2-container .select2-selection.select2-selection--single {
    height: 45px;
    padding: 11px 13px;
    .select2-selection__rendered {
      padding-top: 0px;
      line-height: 22px;
      font-size: 18px;
    }
    .select2-selection__arrow {
      bottom: 9px;
    }
  }
}

.select2-sm {
  .select2-container .select2-selection.select2-selection--single {
    height: 32px;
    padding: 8px 9px;
    .select2-selection__rendered {
      padding-top: 0px;
      line-height: 16px;
      font-size: 13px;
    }
    .select2-selection__arrow {
      bottom: 4px;
    }
  }
}

.input-group-text {
  background-color: $color-contrast-lowest;
  color: fade($color-contrast-high, 67%);
  font-size: 14px;
  line-height: 14px;
  border: 1px solid $color-border-a;
  border-top-color: fade($color-contrast-higher, 21%);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px;
  &.primary {
	background-color: $color-primary;
	color: text-contrast($color-primary);
  }
  &.success {
	background-color: $color-success;
	color: text-contrast($color-success);
  }
  &.complete{
	background-color: $color-complete;
	color: text-contrast($color-complete);
  }
  &.info {
	background-color: $color-info;
	color: text-contrast($color-info);
  }
  &.warning {
	background-color: $color-warning;
	color: text-contrast($color-warning);
  }
  &.danger {
	background-color: $color-danger;
	color: text-contrast($color-danger);
  }
  &.transparent {
    background-color: transparent;
  }
}
.input-group-append {
  .input-group-text {
    @include border-left(0);
  }
}
.input-group-prepend {
  .input-group-text {
    @include border-right(0);
  }
}

/* Form layouts
--------------------------------------------------
*/

@media only screen and (min-width: 540px) {
  form {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .row [class*="col-sm-"] {
      &:not(:first-child),
      &:not(:last-child) {
        @include padding-right(7px);
        @include padding-left(7px);
      }

      &:first-child {
        @include padding-left(0);
      }
      &:last-child {
        @include padding-right(0);
      }
    }
  }
}
@media only screen and (min-width: 720px) {
  form {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .row [class*="col-"] {
      &:not(:first-child),
      &:not(:last-child) {
        @include padding-right(0);
        @include padding-left(0);
      }

      &:first-child {
        @include padding-left(0);
      }
      &:last-child {
        @include padding-right(0);
      }
    }
  }
  form {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .row [class*="col-md-"] {
      &:not(:first-child),
      &:not(:last-child) {
        @include padding-right(7px);
        @include padding-left(7px);
      }

      &:first-child {
        @include padding-left(0);
      }
      &:last-child {
        @include padding-right(0);
      }
    }
  }
}
@media only screen and (min-width: 960px) {
  form {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .row [class*="col-lg-"] {
      &:not(:first-child),
      &:not(:last-child) {
        @include padding-right(7px);
        @include padding-left(7px);
      }

      &:first-child {
        @include padding-left(0);
      }
      &:last-child {
        @include padding-right(0);
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  form {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .row [class*="col-xl-"] {
      &:not(:first-child),
      &:not(:last-child) {
        @include padding-right(7px);
        @include padding-left(7px);
      }

      &:first-child {
        @include padding-left(0);
      }
      &:last-child {
        @include padding-right(0);
      }
    }
  }
}

/* Form layouts  : Horizontal
--------------------------------------------------
*/
.form-horizontal {
  .form-group {
    border-bottom: 1px solid $color-contrast-low;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;
    &:last-child {
      border-bottom: none;
    }
    &:hover .control-label {
      opacity: 1;
      color: $color-contrast-higher;
    }
    &.focused .control-label {
      opacity: 1;
      color: $color-contrast-high;
    }
    .control-label {
      @include text-align(left);
      color: $color-contrast-high;
      @include transition(opacity ease 0.3s);
      &.required {
        &:after {
          content: "Required";
          position: absolute;
          right: 0px;
          font-size: 12px;
          margin-right: 4px;
          color: fade($color-contrast-medium, 87);
        }
      }
    }
  }
  .radio {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .control-label {
    padding-top: 7px;
    margin-bottom: 0;
  }
}
/* Form layouts  : Attached
--------------------------------------------------
*/
.form-group-attached {
  .form-group.form-group-default {
    border-radius: 0;
    margin-bottom: 0;
  }
  & > div {
    margin: 0;
    &:first-child {
      &.row > [class*="col-"] {
        &:first-child .form-group-default {
          @include border-top-left-radius(2px);
        }
        &:last-child .form-group-default {
          @include border-top-right-radius(2px);
        }
      }
      &.form-group-default {
        @include border-top-left-radius(2px);
        @include border-top-right-radius(2px);
      }
    }

    &:last-child {
      &.row > [class*="col-"] {
        &:first-child .form-group-default {
          @include border-bottom-left-radius(2px);
        }
        &:last-child .form-group-default {
          @include border-bottom-right-radius(2px);
        }
      }
      &.form-group-default {
        @include border-bottom-left-radius(2px);
        @include border-bottom-right-radius(2px);
      }
    }
    &.row > [class*="col-"] {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    &.row
      > [class*="col-"]:not(:only-child):not(:last-child)
      > .form-group-default {
      @include border-right-color(transparent);
    }
    &:not(:last-child) .form-group-default,
    &:not(:last-child).form-group-default {
      border-bottom-color: transparent;
    }
  }
}

/* Form layouts  : Responsive Handlers
--------------------------------------------------
*/
@media (max-width: 767px) {
  .form-group-attached .form-group-default {
    border-right-color: $color-border-a !important;
  }
}

@media only screen and (min-width: 768px) {
  .form-group-attached > div.row {
    & > [class*="col-"] {
      .form-group {
        height: 100%;
        width: 100%;
      }
    }
  }
}
/* Form Groups
--------------------------------------------------
*/
.form-group {
  margin-bottom: 10px;
  label:not(.error) {
    color: $color-contrast-high;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1),
      color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  label .help {
    @include margin-left(8px);
  }
  .help {
    font-size: 12px;
    color: fade($color-contrast-higher, 67%);
    letter-spacing: normal;
    line-height: 18px;
    display: block;
    margin-top: 6px;
    margin-left: 3px;
  }
}
//here
@keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-4px);
  }
  25%,
  58% {
    -webkit-transform: translateX(4px);
  }
  75% {
    -webkit-transform: translateX(-2px);
  }
  92% {
    -webkit-transform: translateX(2px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}
.form-group-default {
  background-color: $color-contrast-lowest;
  font-family: $force-mac-font-family;
  position: relative;
  border: 1px solid $color-border-a;
  border-top-color: darken($color-border-a, 10%);
  border-radius: 2px;
  padding-top: 5px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 4px;
  overflow: hidden;
  width: 100%;
  @include transition(background-color 0.2s ease);
  cursor: text;
  &.form-check-group {
    cursor: default;
    label {
      cursor: pointer;
    }
  }
  &.required:after {
    color: $color-danger;
    content: "*";
    font-family: $force-mac-font-family;
    font-size: 20px;
    position: absolute;
    @include right(10px);
    top: 2px;
  }
  &.disabled {
    background: mix($color-contrast-lower, #fff, 50%);
    color: fade($color-contrast-high, 23%);
    cursor: not-allowed;
    &.focused {
      border: 1px solid $color-primary;
      label {
        opacity: 1;
      }
    }
    label {
      opacity: 0.57;
      cursor: not-allowed;
    }
  }
  &.focused {
    border: 1px solid $color-primary !important;
    label {
      opacity: 0.67;
      @include backface-visibility(hidden);
      font-size: 12px;
      letter-spacing: normal;
    }
  }
  &.has-error {
    label {
      color: $color-danger-dark;
      animation-name: shake;
      animation-duration: 0.4s;
      animation-iteration-count: 1;
    }
  }
  &.has-success,
  &.has-error {
    .form-control-feedback {
      display: none !important;
    }
  }
  &.has-success .form-control,
  &.has-success .form-control:focus,
  &.has-error .form-control,
  &.has-error .form-control:focus {
    border: none;
    box-shadow: none;
  }
  & + .error {
    margin-top: -10px;
    padding: 3px 7px 2px 4px;
    display: flex;
    letter-spacing: normal;
    animation-name: show-msg;
    animation-duration: 450ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    &:after {
      content: "";
      width: 0%;
      position: absolute;
      height: 1px;
      background-color: $color-danger;
      top: -1px;
      right: 0px;
      animation-name: show-line;
      animation-delay: 300ms;
      animation-duration: 350ms;
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      animation-fill-mode: forwards;
    }
  }
  // Key Frame Animations for error
  @keyframes show-msg {
    0% {
      height: 0px;
      opacity: 0;
    }
    100% {
      height: 25px;
      opacity: 1;
    }
  }
  @keyframes show-line {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  &.input-group {
    padding: 0;
    & > label {
      margin-top: 6px;
      @include padding-left(12px);
      &.inline {
        margin-top: 6px;
        float: left;
      }
    }
    & > .form-control {
      margin-top: -2px;
      margin-bottom: 3px;
      @include padding-left(12px);
    }
    label {
      margin-top: 6px;
      padding-left: 12px;
    }
    .form-input-group {
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0;
    }
    .form-control {
      margin-top: -2px;
      margin-bottom: 3px;
      padding-left: 12px;
      width: 100%;
    }
    .input-group-text {
      border: 0;
      border-radius: 0;
      min-width: 50px;
      i {
        width: 100%;
      }
    }
    .btn {
      border-top: 0;
      border-bottom: 0;
    }
  }
  & .form-control {
    border: none;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
    @include box-shadow(none);
    &.error {
      color: $color-contrast-higher;
    }
    &:focus {
      background: none;
      @include placeholder(inherit, 0.77);
    }
    // Webkit Autofill
    &:-webkit-autofill,
    &.error:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px $color-contrast-lowest inset !important;
      &:focus {
        -webkit-box-shadow: 0 0 0 1000px $color-contrast-lowest inset !important;
      }
    }
  }
  & textarea.form-control {
    padding-top: 5px;
    line-height: 2;
  }
  label {
    margin: 0;
    display: block;
    opacity: 1;
    cursor: text;
    @include transition(opacity 0.2s ease);
    &.label-lg {
      font-size: 13px;
      left: 13px;
      top: 9px;
    }
    &.label-sm {
      font-size: 11px;
      left: 11px;
      top: 6px;
    }
    &.highlight {
      opacity: 1;
    }
    &.fade {
      opacity: 0.5;
      @include backface-visibility(hidden);
      font-size: 12px;
      letter-spacing: normal;
    }
  }
  & > .input-lg {
    height: 29px;
    min-height: 29px;
    @include padding-left(1px);
  }
  & > .input-sm {
    min-height: 18px;
    height: 18px;
  }
  &.form-group-default-select {
    overflow: visible;
    .ui-select-container.ui-select-bootstrap.dropdown {
      .ui-select-match {
        padding-top: 6px;
        height: 23px;
      }
    }
    .ui-select-container {
      .select2-choices {
        border: 0px;
      }
    }
  }
  &.form-group-default-select2 {
    padding: 0;
    & > label {
      position: absolute;
      z-index: 10;
      @include padding(5px, 12px, 0, 9px);
      color: $color-contrast-medium;
      &.label-lg {
        @include left(0);
        top: 0;
      }
      &.label-sm {
        @include left(0);
        top: 0;
      }
    }
    .select2-container {
      .select2-selection--single {
        padding-top: 20px;
        height: 52px;
        .select2-arrow b:before {
          top: 20px;
        }
        .select2-chosen {
          @include padding-left(3px);
          padding-top: 1px;
        }
      }
      .select2-selection--multiple {
        padding-top: 19px;
        padding-bottom: 0;
        min-height: 52px;
        border: 0px !important;
        .select2-selection__rendered .select2-selection__choice {
          margin-top: 5px;
        }
      }
    }
    &.input-lg {
      height: auto;
      padding: 0;
      .select2-container .select2-selection--single {
        padding-top: 26px;
        height: 58px;
        .select2-selection__rendered {
          font-size: 18px;
        }
      }
    }
    &.input-sm {
      height: auto;
      padding: 0;
      .select2-container .select2-selection--single {
        padding-top: 21px;
        height: 49px;
        .select2-selection__rendered {
          font-size: 13px;
        }
      }
    }
  }
  &.form-group-default-selectFx {
    padding: 0;
    & > label {
      position: absolute;
      z-index: 10;
      @include padding(7px, 12px, 0, 12px);
      &.label-lg {
        left: 0;
        top: 0;
      }
      &.label-sm {
        left: 0;
        top: 0;
      }
    }
    .cs-wrapper {
      .cs-placeholder {
        padding-top: 28px;
        height: 52px;
        @include padding-left(12px);
      }
      .cs-select {
        height: auto;
        > span:after,
        .cs-selected span:after {
          top: 39px;
        }
        &.input-lg .cs-placeholder {
          height: 60px;
        }
        &.input-sm .cs-placeholder {
          height: 50px;
        }
      }
      .dropdown-placeholder {
        vertical-align: top;
      }
    }
  }
  .input-group-append {
    .input-group-text:before {
      content: "";
      width: 1px;
      height: 80%;
      top: 0px;
      left: -10px;
      position: relative;
      background-color: rgba(0, 0, 0, 0.16);
    }
  }
  .input-group-prepend {
    .input-group-text:before {
      content: "";
      width: 1px;
      height: 80%;
      top: 0px;
      right: -10px;
      position: relative;
      background-color: rgba(0, 0, 0, 0.16);
    }
  }
}

/* Form validation
--------------------------------------------------
*/
.has-success {
  @include form-control-validation(
    $color-success-dark,
    $color-success-dark,
    $color-success
  );
}
.has-warning {
  @include form-control-validation(
    $color-warning-dark,
    $color-warning-dark,
    $color-warning
  );
}
.has-error {
  @include form-control-validation($color-danger, $color-danger, $color-danger);
}
label.error {
  font-size: 12px;
  color: $color-danger-dark;
  display: block;
}
.validation-popover {
  background: $color-black;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
  .popover-body {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 1.4;
    color: $color-white;
  }
  & > .arrow::after {
    border-top-color: $color-black;
  }
}

/* Plugins
--------------------------------------------------
Datepicker
https://github.com/eternicode/bootstrap-datepicker
*/
.datepicker {
  padding: 9px;
  border-radius: 2px;
  font-size: 12px;
  &.dropdown-menu {
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
      rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  }
  &:after {
    border-bottom-color: $color-contrast-lowest;
  }
  thead {
    tr {
      .datepicker-switch {
        color: $color-contrast-medium;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.006em;
      }
      .next,
      .prev {
        color: $color-primary;
        content: "";
        font-size: 0px;
        &:before {
          color: $color-primary;
          font-family: "pages-icon";
          font-size: 18px;
        }
      }
      .prev:before {
        content: "\e989";
      }
      .next:before {
        content: "\e988";
      }
      .dow {
        font-family: $interface-font-family;
        color: $color-contrast-higher;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0em;
        border-bottom: 1px solid #d0d0d0;
      }
      th {
        width: 34px;
        height: 29px;
      }
    }
  }
  table {
    height: 250px;
    tr {
      td {
        padding-bottom: 14px;
        width: 31px;
        height: 29px;
        &.day {
          border-top: 1px solid $color-border-a;
          position: relative;
          color: $color-contrast-higher;
          font-family: $interface-font-family;
          font-size: 12px;
          &:hover:not(.active) {
            background-color: transparent;
            z-index: 1;
            &:before {
              content: "";
              width: 24px;
              height: 24px;
              background-color: transparent;
              border-radius: 50%;
              -webkit-box-shadow: 0px 0px 0px 1px $color-contrast-medium;
              -moz-box-shadow: 0px 0px 0px 1px $color-contrast-medium;
              box-shadow: 0px 0px 0px 1px $color-contrast-medium;
              display: block;
              position: absolute;
              top: 2px;
              left: 3px;
              z-index: -1;
            }
          }
        }
        &.active {
          background-color: transparent !important;
          z-index: 1;
          cursor: default !important;
          border-color: $color-border-a !important;
          &:before {
            content: "";
            width: 24px;
            height: 24px;
            background-color: $color-primary;
            border-radius: 50%;
            display: block;
            position: absolute;
            top: 2px;
            left: 3px;
            z-index: -1;
          }
          &,
          &:hover,
          &.disabled,
          &.disabled:hover {
            background-image: none;
            text-shadow: none;
            font-weight: 600;
          }
        }
        &.today {
          &,
          &:hover,
          &.disabled,
          &.disabled:hover {
            background-color: $color-contrast-lower;
            background-image: none;
            color: $color-contrast-lowest;
          }
        }
        &.disabled {
          color: $color-hinted-a;
          font-weight: 500;
          position: relative;
          opacity: 0.78;
          &:before {
            display: none !important;
          }
          &:after {
            content: "";
            display: block;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border: 2px solid #fff;
            position: absolute;
            top: 1px;
            left: 1px;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARBAMAAADJQ1rJAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAYUExURQAAAEdwTAAAAAAAAAAAAAAAAAAAAAAAAKkycPQAAAAIdFJOU0AACuuWFpQXdpvuhwAAAFRJREFUCNc1zLEJgEAAQ9GIC9y3sVUQa0ewsdcVBFdwfsNd7D4PEnXwzlA2wXgDvfRTUahzNaIo5Aq5QkwKDbtCy6nQ4ZdGl6vS422l9XKFXCFXCH14sRAYdHQ86wAAAABJRU5ErkJggg==);
            background-repeat: repeat;
            background-size: 17px 15px;
            opacity: 0.47;
          }
          &:hover {
            background-color: transparent;
            cursor: default;
            color: rgb(97, 97, 97); //here
          }
        }
        &.old,
        &.new {
          color: $color-contrast-medium;
          opacity: 0.67;
          font-weight: 400;
          border-top: 1px solid transparent;
          pointer-events: none;
          &:after {
            opacity: 0;
          }
          &:before {
            display: none;
          }
        }
        span {
          border-radius: 1px;
          width: 48px;
          height: 42px;
          line-height: 40px;
          font-weight: 500;
          font-size: 12px;
          border-top: 1px solid $color-border-a;
          margin: 0;
          &.active {
            background-color: $color-primary !important;
          }
          &.active,
          &.active:hover,
          &.active.disabled,
          &.active.disabled:hover {
            background-image: none;
            border: none;
            text-shadow: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding-bottom: 6px;
        }
        .odd {
          color: #d0d3d8;
        }
      }
      tr:first-child {
        td.day {
          padding-top: 14px;
          padding-bottom: 6px;
          &:hover {
            &:before {
              top: 12px;
              left: 4px;
            }
          }
        }
      }
    }
  }
  &.dropdown-menu {
    border-color: $color-contrast-low;
    color: $color-contrast-high;
  }
  &.datepicker-dropdown.datepicker-orient-bottom:before {
    border-color: $color-contrast-low;
  }
}

.datepicker-inline {
  width: auto;
}
.input-daterange .input-group-addon {
  text-shadow: none;
  border: 0;
  margin: 0;
}

/* Timepicker
https://github.com/m3wolf/bootstrap3-timepicker
*/

.bootstrap-timepicker-widget {
  table td a i {
    font-size: 12px;
  }
  a.btn,
  .bootstrap-timepicker-widget input {
    @include border-radius(2px);
  }
  &.dropdown-menu {
    background: $color-contrast-lowest;
  }
}

/* Daterangepicker
http://www.dangrossman.info/
*/

.daterangepicker {
  background: $color-contrast-lowest;
  .calendar {
    .calendar-date {
      border: 1px solid $color-border-a;
    }
    .prev,
    .next,
    th {
      color: $color-primary;
      text-transform: uppercase;
      font-size: 11px;
    }
    .month {
      color: #6f7b8a;
      font-size: 13px;
    }
  }
  td.active,
  td.active:hover {
    background-color: $color-primary;
    border-color: $color-primary;
  }
  td.in-range {
    // background:$color-primary-lighter;
  }
}

/* Select2
http://ivaynberg.github.io/select2/
*/
.form-group-default .select2-container .select2-selection,
.select2-container-multi .select2-choices {
  border-color: transparent;
}
select.full-width + .select2-container {
  width: 100% !important;
}
.select2-container {
  //Chrome outline fix
  *:focus {
    outline: none;
  }
  .select2-selection {
    background-image: none;
    border-radius: 2px;
    border: 1px solid $color-border-a;
    padding: 2px 9px;
    transition: border 0.2s linear 0s;
    min-height: 35px;
    display: flex;
    align-items: center;
    .select2-selection__rendered {
      padding: 0;
      display: flex;
      align-items: center;
      .select2-selection__clear {
        font-weight: normal;
        right: 16px;
        width: 20px;
        text-align: center;
        color: #888;
      }
    }
    .select2-selection__arrow {
      top: auto;
      bottom: 5px;
      right: 10px !important;
      @include left(auto);
      background: transparent;
      border-left: 0px;
      @include transition(all 0.3s ease);
    }
    &.select2-selection--single {
      height: 35px;
    }
    & > .select2-chosen {
      @include margin-right(26px);
      @include margin-left(0);
    }
  }
  &.select2-drop-above {
    .select2-choice {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
}
.select2-container--default {
  .select2-results__option[aria-selected="true"] {
    background-color: transparent;
  }
  .select2-results__group {
    color: $color-contrast-higher;
  }
}
.select2-search input,
.select2-search-choice-close,
.select2-container .select2-choice abbr,
.select2-container .select2-choice .select2-arrow b {
  background-image: none !important;
}
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background-image: none !important;
  border: 1px solid $color-border-a;
  background: $color-contrast-lower;
}
.select2-dropdown-open {
  .select2-choice {
    .select2-arrow {
      @include scale(scale(1, -1));
    }
  }
}
.select2-dropdown {
  border: 1px solid $color-border-a;
  border-bottom: none;
  border-radius: 2px;
  padding-top: 0px;
  &.select2-drop-above {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: none;
  }
}
.select2-container--open {
  .select2-selection {
    box-shadow: none;
    border: 1px solid $color-primary !important;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .select2-dropdown {
    top: 1px;
  }
}
.select2-search--dropdown {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  input {
    background: $color-contrast-lowest !important;
    vertical-align: baseline;
    line-height: 28px;
    border-radius: 2px;
    border: none;
    font-size: 12px;
    border: 1px solid $color-border-a !important;
  }
}
.select2-results {
  @include margin(3px, 8px, 10px, 8px);
  li {
    color: $color-contrast-high;
    &.select2-result-with-children {
      & > .select2-result-label {
        color: $color-contrast-higher;
        &:first-child {
          padding-top: 8px;
        }
      }
    }
  }
  .select2-results__option--highlighted {
    background-color: $color-contrast-lower !important;
    border-radius: 3px;
    color: $color-contrast-high !important;
  }
  .select2-no-results,
  .select2-searching,
  .select2-selection-limit {
    background: none;
    color: $color-contrast-high;
    font-size: 12px;
    @include padding-left(8px);
    padding-top: 0px;
    position: relative;
    top: -5px;
  }
}

.select2-dropdown {
  border: 1px solid $color-border-a;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  padding-top: 5px;
  z-index: $zIndex-navbar - 10;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
.select2-container--open
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: $color-contrast-lowest !important;
}
.select2-selection--multiple {
  .select2-selection__rendered {
    background-image: none;
    .select2-selection__choice {
      background-color: $color-contrast-low;
      background-image: none;
      border: none;
      box-shadow: none;
      color: inherit;
      border-radius: 99px;
      margin: 0px 6px 0px 0;
      padding: 0 8px 0 7px;
      line-height: 21px;
      .select2-selection__choice__remove {
        color: #626262;
        font-weight: 900;
        margin-right: 4px;
        position: relative;
        top: -1px;
      }
    }
  }
  &.select2-container-active {
    .select2-choices {
      border: 1px solid $color-border-a;
      box-shadow: none;
    }
  }
  .select2-search-choice-close {
    @include left(6px);
  }
}
.select2-search-choice-close {
  background: none;
  top: 4px;
  @include right(0);
  &:hover {
    text-decoration: none;
  }
}
.select2-drop-multi .select2-results {
  .select2-no-results,
  .select2-searching,
  .select2-selection-limit {
    top: 0px;
  }
}
.select2 {
  &.form-control {
    padding: 0;
    box-shadow: none;
    border: 0;
  }
}

.select2-drop-mask {
  z-index: 700;
}

.ui-select-bootstrap .ui-select-choices-row.active > span {
  background: $color-contrast-lower;
  border-radius: 3px;
  color: $color-contrast-high;
}
.ui-select-bootstrap > .ui-select-choices {
  background-color: $color-contrast-lowest;
  // box-shadow: 0px 4px 5px rgba(0,​ 0,​ 0,​ 0.15);
}
.ui-select-choices-group-label {
  color: $color-contrast-higher;
  font-weight: bold;
}
.modal-open {
  .select2-drop-active {
    z-index: 1051;
  }
  .select2-container {
    z-index: 1051;
  }
  .datepicker.dropdown-menu {
    z-index: 1051 !important;
  }
  .select2-drop-mask {
    z-index: 1050;
  }
  .cs-skin-slide.cs-active {
    z-index: 1050;
  }
}

.dropdown-placeholder {
  display: inline-block;
  vertical-align: middle;
}
.dropdown-mask {
  bottom: 0;
  display: none;
  left: 0;
  outline: 0 none;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $zIndex-dropdownMask;
}

.ui-select-bootstrap {
  *:focus {
    outline: none;
  }
  .ui-select-choices-row > span {
    padding-top: 6px;
    padding-bottom: 6px;
    line-height: 24px;
    font-size: 14px;
  }
  .ui-select-match {
    box-shadow: none;
  }
  .ui-select-choices-group-label {
    padding-left: 15px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 700;
    color: #626262;
  }
  .ui-select-match-item {
  }
  .ui-select-match.btn-default-focus {
    background-color: transparent;
  }
}
.form-group-default-select {
  padding-bottom: 2px;
  .ui-select-bootstrap {
    .ui-select-dropdown {
      width: calc(100% + 24px) !important;
      margin-left: -12px;
      padding: 10px;
    }
    .ui-select-match-text {
      width: calc(100% + 24px);
      margin-top: -17px;
      margin-left: -16px;
      padding: 16px 16px 0;
    }
    .ui-select-match {
      padding-top: 2px !important;
      i.caret {
        margin-top: 10px;
      }
      &.btn-default-focus {
        outline: none;
      }
      & > .ui-select-toggle {
        width: calc(100% + 27px);
        height: 50px;
        margin-top: -34px;
        margin-left: -16px;
        padding: 32px 16px 16px;
      }
    }
    .dropdown-menu .divider {
      display: none;
    }
    &.ui-select-container {
      margin-top: -3px;
      padding-bottom: 2px;
    }
  }
  .ui-select-multiple {
    margin-top: -7px;
    .select2-search-field input {
      height: 23px !important;
    }
    .select2-search-choice {
      margin-top: 2px;
      margin-bottom: 0;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .select2-container-multi .select2-choices {
    background-color: transparent;
  }
}

/* Bootstrap Tags input
https://github.com/timschlechter/bootstrap-tagsinput
*/

.form-group-default {
  .bootstrap-tagsinput {
    border: 0px;
    @include padding-left(0);
    margin-bottom: 0;
    padding-top: 0;
    vertical-align: top;
    padding-bottom: 0px;
    @include padding-left(0);
    margin-top: -3px;
    .tag {
      padding: 2px 9px;
      line-height: 18px;
      margin-bottom: 2px;
      @include margin-left(0);
      @include margin-right(2px);
    }
    input {
      min-height: 20px;
      &:first-child {
        @include margin-left(0);
      }
    }
  }
}
.bootstrap-tagsinput {
  @include flex();
  @include flex-row();
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  background-color: transparent;
  border: 1px solid $color-border-a;
  border-radius: 4px;
  padding: 0;
  padding-top: 5px;
  @include padding-right(5px);
  @include box-shadow(none);
  width: 100%;
  @include transition(background 0.2s linear 0s);
  &.active-element {
    background-color: $color-contrast-low;
  }
  input {
    border: none;
    margin: 5px;
    margin-top: 0;
    @include margin-right(0);
    min-height: 25px;
    min-width: 10em !important;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    //Older browsers
    -webkit-flex-shrink: 0;
    -webkit-flex-grow: 1;
    -webkit-flex-basis: auto;
  }
  .tag {
    padding: 3px 9px;
    @include padding-right(6px);
    border-radius: 3px;
    line-height: 18px;
    display: flex;
    margin: 5px;
    margin-top: 0;
    @include margin-right(0);
  }
  .tag [data-role="remove"] {
    @include margin-left(4px);
    display: flex;
    align-items: center;
  }
  .tag[data-role="remove"]:hover:active,
  .tag [data-role="remove"]:hover {
    box-shadow: none;
  }
  .tag [data-role="remove"]:after {
    font-family: "pages-icon";
    content: "\e966";
    padding: 0;
    font-size: 14px;
  }
}

/* Quill Editor v1.3.6
https://github.com/HackerWins/summernote
*/

.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;

  &.ql-disabled {
    .ql-tooltip {
      visibility: hidden;
    }

    .ql-editor ul[data-checked] > li::before {
      pointer-events: none;
    }
  }
}

.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;

  p {
    margin: 0;
    padding: 0;
  }
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;

  > * {
    cursor: text;
  }

  p,
  ol,
  ul,
  pre,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
      list-9;
  }

  ol,
  ul {
    padding-left: 1.5em;
  }

  ol > li {
    list-style-type: none;
  }

  ul {
    > li {
      list-style-type: none;

      &::before {
        content: "\2022";
      }
    }

    &[data-checked="true"],
    &[data-checked="false"] {
      pointer-events: none;
    }

    &[data-checked="true"] > li *,
    &[data-checked="false"] > li * {
      pointer-events: all;
    }

    &[data-checked="true"] > li::before,
    &[data-checked="false"] > li::before {
      color: #777;
      cursor: pointer;
      pointer-events: all;
    }

    &[data-checked="true"] > li::before {
      content: "\2611";
    }

    &[data-checked="false"] > li::before {
      content: "\2610";
    }
  }

  li {
    &::before {
      display: inline-block;
      white-space: nowrap;
      width: 1.2em;
    }

    &:not(.ql-direction-rtl)::before {
      margin-left: -1.5em;
      margin-right: 0.3em;
      text-align: right;
    }

    &.ql-direction-rtl::before {
      margin-left: 0.3em;
      margin-right: -1.5em;
    }
  }

  ol li:not(.ql-direction-rtl),
  ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }

  ol li.ql-direction-rtl,
  ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }

  ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
      list-9;
    counter-increment: list-0;

    &:before {
      content: counter(list-0, decimal) ". ";
    }

    &.ql-indent-1 {
      counter-increment: list-1;

      &:before {
        content: counter(list-1, lower-alpha) ". ";
      }

      counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }

    &.ql-indent-2 {
      counter-increment: list-2;

      &:before {
        content: counter(list-2, lower-roman) ". ";
      }

      counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }

    &.ql-indent-3 {
      counter-increment: list-3;

      &:before {
        content: counter(list-3, decimal) ". ";
      }

      counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
    }

    &.ql-indent-4 {
      counter-increment: list-4;

      &:before {
        content: counter(list-4, lower-alpha) ". ";
      }

      counter-reset: list-5 list-6 list-7 list-8 list-9;
    }

    &.ql-indent-5 {
      counter-increment: list-5;

      &:before {
        content: counter(list-5, lower-roman) ". ";
      }

      counter-reset: list-6 list-7 list-8 list-9;
    }

    &.ql-indent-6 {
      counter-increment: list-6;

      &:before {
        content: counter(list-6, decimal) ". ";
      }

      counter-reset: list-7 list-8 list-9;
    }

    &.ql-indent-7 {
      counter-increment: list-7;

      &:before {
        content: counter(list-7, lower-alpha) ". ";
      }

      counter-reset: list-8 list-9;
    }

    &.ql-indent-8 {
      counter-increment: list-8;

      &:before {
        content: counter(list-8, lower-roman) ". ";
      }

      counter-reset: list-9;
    }

    &.ql-indent-9 {
      counter-increment: list-9;

      &:before {
        content: counter(list-9, decimal) ". ";
      }
    }
  }

  .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }

  li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }

  .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }

  li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }

  .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }

  li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }

  .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }

  li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }

  .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }

  li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }

  .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }

  li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }

  .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }

  li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }

  .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }

  li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }

  .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }

  li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }

  .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }

  li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }

  .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }

  li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }

  .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }

  li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }

  .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }

  li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }

  .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }

  li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }

  .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }

  li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }

  .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }

  li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }

  .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }

  li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }

  .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }

  li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }

  .ql-video {
    display: block;
    max-width: 100%;

    &.ql-align-center {
      margin: 0 auto;
    }

    &.ql-align-right {
      margin: 0 0 0 auto;
    }
  }

  .ql-bg-black {
    background-color: #000;
  }

  .ql-bg-red {
    background-color: #e60000;
  }

  .ql-bg-orange {
    background-color: #f90;
  }

  .ql-bg-yellow {
    background-color: #ff0;
  }

  .ql-bg-green {
    background-color: #008a00;
  }

  .ql-bg-blue {
    background-color: #06c;
  }

  .ql-bg-purple {
    background-color: #93f;
  }

  .ql-color-white {
    color: #fff;
  }

  .ql-color-red {
    color: #e60000;
  }

  .ql-color-orange {
    color: #f90;
  }

  .ql-color-yellow {
    color: #ff0;
  }

  .ql-color-green {
    color: #008a00;
  }

  .ql-color-blue {
    color: #06c;
  }

  .ql-color-purple {
    color: #93f;
  }

  .ql-font-serif {
    font-family: Georgia, Times New Roman, serif;
  }

  .ql-font-monospace {
    font-family: Monaco, Courier New, monospace;
  }

  .ql-size-small {
    font-size: 0.75em;
  }

  .ql-size-large {
    font-size: 1.5em;
  }

  .ql-size-huge {
    font-size: 2.5em;
  }

  .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-align-right {
    text-align: right;
  }

  &.ql-blank::before {
    color: rgba(0, 0, 0, 0.6);
    content: attr(data-placeholder);
    font-style: italic;
    left: 15px;
    pointer-events: none;
    position: absolute;
    right: 15px;
  }
}

.ql-snow {
  &.ql-toolbar:after,
  .ql-toolbar:after {
    clear: both;
    content: "";
    display: table;
  }

  &.ql-toolbar button,
  .ql-toolbar button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 24px;
    padding: 3px 5px;
    width: 28px;
  }

  &.ql-toolbar button svg,
  .ql-toolbar button svg {
    float: left;
    height: 100%;
  }

  &.ql-toolbar button:active:hover,
  .ql-toolbar button:active:hover {
    outline: none;
  }

  &.ql-toolbar input.ql-image[type="file"],
  .ql-toolbar input.ql-image[type="file"] {
    display: none;
  }

  &.ql-toolbar button:hover,
  .ql-toolbar button:hover,
  &.ql-toolbar button:focus,
  .ql-toolbar button:focus,
  &.ql-toolbar button.ql-active,
  .ql-toolbar button.ql-active,
  &.ql-toolbar .ql-picker-label:hover,
  .ql-toolbar .ql-picker-label:hover,
  &.ql-toolbar .ql-picker-label.ql-active,
  .ql-toolbar .ql-picker-label.ql-active,
  &.ql-toolbar .ql-picker-item:hover,
  .ql-toolbar .ql-picker-item:hover,
  &.ql-toolbar .ql-picker-item.ql-selected,
  .ql-toolbar .ql-picker-item.ql-selected {
    color: #06c;
  }

  &.ql-toolbar button:hover .ql-fill,
  .ql-toolbar button:hover .ql-fill,
  &.ql-toolbar button:focus .ql-fill,
  .ql-toolbar button:focus .ql-fill,
  &.ql-toolbar button.ql-active .ql-fill,
  .ql-toolbar button.ql-active .ql-fill,
  &.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-toolbar .ql-picker-label:hover .ql-fill,
  &.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  &.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-toolbar .ql-picker-item:hover .ql-fill,
  &.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  &.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-toolbar button:hover .ql-stroke.ql-fill,
  &.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-toolbar button:focus .ql-stroke.ql-fill,
  &.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  &.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  &.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  &.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: #06c;
  }

  &.ql-toolbar button:hover .ql-stroke,
  .ql-toolbar button:hover .ql-stroke,
  &.ql-toolbar button:focus .ql-stroke,
  .ql-toolbar button:focus .ql-stroke,
  &.ql-toolbar button.ql-active .ql-stroke,
  .ql-toolbar button.ql-active .ql-stroke,
  &.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-toolbar .ql-picker-label:hover .ql-stroke,
  &.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  &.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-toolbar .ql-picker-item:hover .ql-stroke,
  &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  &.ql-toolbar button:hover .ql-stroke-miter,
  .ql-toolbar button:hover .ql-stroke-miter,
  &.ql-toolbar button:focus .ql-stroke-miter,
  .ql-toolbar button:focus .ql-stroke-miter,
  &.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-toolbar button.ql-active .ql-stroke-miter,
  &.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  &.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  &.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: #06c;
  }

  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .ql-hidden {
    display: none;
  }

  .ql-out-bottom,
  .ql-out-top {
    visibility: hidden;
  }

  .ql-tooltip {
    position: absolute;
    transform: translateY(10px);

    a {
      cursor: pointer;
      text-decoration: none;
    }

    &.ql-flip {
      transform: translateY(-10px);
    }
  }

  .ql-formats {
    display: inline-block;
    vertical-align: middle;

    &:after {
      clear: both;
      content: "";
      display: table;
    }
  }

  .ql-stroke {
    fill: none;
    stroke: #444;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }

  .ql-stroke-miter {
    fill: none;
    stroke: #444;
    stroke-miterlimit: 10;
    stroke-width: 2;
  }

  .ql-fill,
  .ql-stroke.ql-fill {
    fill: #444;
  }

  .ql-empty {
    fill: none;
  }

  .ql-even {
    fill-rule: evenodd;
  }

  .ql-thin,
  .ql-stroke.ql-thin {
    stroke-width: 1;
  }

  .ql-transparent {
    opacity: 0.4;
  }

  .ql-direction {
    svg:last-child {
      display: none;
    }

    &.ql-active svg {
      &:last-child {
        display: inline;
      }

      &:first-child {
        display: none;
      }
    }
  }

  .ql-editor {
    h1 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.5em;
    }

    h3 {
      font-size: 1.17em;
    }

    h4 {
      font-size: 1em;
    }

    h5 {
      font-size: 0.83em;
    }

    h6 {
      font-size: 0.67em;
    }

    a {
      text-decoration: underline;
    }

    blockquote {
      border-left: 4px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
    }

    code {
      background-color: #f0f0f0;
      border-radius: 3px;
    }

    pre {
      background-color: #f0f0f0;
      border-radius: 3px;
      white-space: pre-wrap;
      margin-bottom: 5px;
      margin-top: 5px;
      padding: 5px 10px;
    }

    code {
      font-size: 85%;
      padding: 2px 4px;
    }

    pre.ql-syntax {
      background-color: #23241f;
      color: #f8f8f2;
      overflow: visible;
    }

    img {
      max-width: 100%;
    }
  }

  .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    position: relative;
    vertical-align: middle;
  }

  .ql-picker-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding-left: 8px;
    padding-right: 2px;
    position: relative;
    width: 100%;

    &::before {
      display: inline-block;
      line-height: 22px;
    }
  }

  .ql-picker-options {
    background-color: #fff;
    display: none;
    min-width: 100%;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap;

    .ql-picker-item {
      cursor: pointer;
      display: block;
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }

  .ql-picker.ql-expanded {
    .ql-picker-label {
      color: #ccc;
      z-index: 2;

      .ql-fill {
        fill: #ccc;
      }

      .ql-stroke {
        stroke: #ccc;
      }
    }

    .ql-picker-options {
      display: block;
      margin-top: -1px;
      top: 100%;
      z-index: 1;
    }
  }

  .ql-color-picker,
  .ql-icon-picker {
    width: 28px;
  }

  .ql-color-picker .ql-picker-label,
  .ql-icon-picker .ql-picker-label {
    padding: 2px 4px;
  }

  .ql-color-picker .ql-picker-label svg {
    right: 4px;
  }

  .ql-icon-picker {
    .ql-picker-label svg {
      right: 4px;
    }

    .ql-picker-options {
      padding: 4px 0px;
    }

    .ql-picker-item {
      height: 24px;
      width: 24px;
      padding: 2px 4px;
    }
  }

  .ql-color-picker {
    .ql-picker-options {
      padding: 3px 5px;
      width: 152px;
    }

    .ql-picker-item {
      border: 1px solid transparent;
      float: left;
      height: 16px;
      margin: 2px;
      padding: 0px;
      width: 16px;
    }
  }

  .ql-picker {
    &:not(.ql-color-picker):not(.ql-icon-picker) svg {
      position: absolute;
      margin-top: -9px;
      right: 0;
      top: 50%;
      width: 18px;
    }

    &.ql-header .ql-picker-label[data-label]:not([data-label=""])::before,
    &.ql-font .ql-picker-label[data-label]:not([data-label=""])::before,
    &.ql-size .ql-picker-label[data-label]:not([data-label=""])::before,
    &.ql-header .ql-picker-item[data-label]:not([data-label=""])::before,
    &.ql-font .ql-picker-item[data-label]:not([data-label=""])::before,
    &.ql-size .ql-picker-item[data-label]:not([data-label=""])::before {
      content: attr(data-label);
    }

    &.ql-header {
      width: 98px;

      .ql-picker-label::before,
      .ql-picker-item::before {
        content: "Normal";
      }

      .ql-picker-label[data-value="1"]::before,
      .ql-picker-item[data-value="1"]::before {
        content: "Heading 1";
      }

      .ql-picker-label[data-value="2"]::before,
      .ql-picker-item[data-value="2"]::before {
        content: "Heading 2";
      }

      .ql-picker-label[data-value="3"]::before,
      .ql-picker-item[data-value="3"]::before {
        content: "Heading 3";
      }

      .ql-picker-label[data-value="4"]::before,
      .ql-picker-item[data-value="4"]::before {
        content: "Heading 4";
      }

      .ql-picker-label[data-value="5"]::before,
      .ql-picker-item[data-value="5"]::before {
        content: "Heading 5";
      }

      .ql-picker-label[data-value="6"]::before {
        content: "Heading 6";
      }

      .ql-picker-item {
        &[data-value="6"]::before {
          content: "Heading 6";
        }

        &[data-value="1"]::before {
          font-size: 2em;
        }

        &[data-value="2"]::before {
          font-size: 1.5em;
        }

        &[data-value="3"]::before {
          font-size: 1.17em;
        }

        &[data-value="4"]::before {
          font-size: 1em;
        }

        &[data-value="5"]::before {
          font-size: 0.83em;
        }

        &[data-value="6"]::before {
          font-size: 0.67em;
        }
      }
    }

    &.ql-font {
      width: 108px;

      .ql-picker-label::before,
      .ql-picker-item::before {
        content: "Sans Serif";
      }

      .ql-picker-label[data-value="serif"]::before,
      .ql-picker-item[data-value="serif"]::before {
        content: "Serif";
      }

      .ql-picker-label[data-value="monospace"]::before {
        content: "Monospace";
      }

      .ql-picker-item {
        &[data-value="monospace"]::before {
          content: "Monospace";
        }

        &[data-value="serif"]::before {
          font-family: Georgia, Times New Roman, serif;
        }

        &[data-value="monospace"]::before {
          font-family: Monaco, Courier New, monospace;
        }
      }
    }

    &.ql-size {
      width: 98px;

      .ql-picker-label::before,
      .ql-picker-item::before {
        content: "Normal";
      }

      .ql-picker-label[data-value="small"]::before,
      .ql-picker-item[data-value="small"]::before {
        content: "Small";
      }

      .ql-picker-label[data-value="large"]::before,
      .ql-picker-item[data-value="large"]::before {
        content: "Large";
      }

      .ql-picker-label[data-value="huge"]::before {
        content: "Huge";
      }

      .ql-picker-item {
        &[data-value="huge"]::before {
          content: "Huge";
        }

        &[data-value="small"]::before {
          font-size: 10px;
        }

        &[data-value="large"]::before {
          font-size: 18px;
        }

        &[data-value="huge"]::before {
          font-size: 32px;
        }
      }
    }
  }

  .ql-color-picker {
    &.ql-background .ql-picker-item {
      background-color: #fff;
    }

    &.ql-color .ql-picker-item {
      background-color: #000;
    }
  }
}

@media (pointer: coarse) {
  .ql-snow {
    &.ql-toolbar button:hover:not(.ql-active),
    .ql-toolbar button:hover:not(.ql-active) {
      color: #444;
    }

    &.ql-toolbar button:hover:not(.ql-active) .ql-fill,
    .ql-toolbar button:hover:not(.ql-active) .ql-fill,
    &.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
    .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
      fill: #444;
    }

    &.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
    .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
    &.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
    .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
      stroke: #444;
    }
  }
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;

  .ql-formats {
    margin-right: 15px;
  }

  .ql-picker-label {
    border: 1px solid transparent;
  }

  .ql-picker-options {
    border: 1px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
  }

  .ql-picker.ql-expanded {
    .ql-picker-label,
    .ql-picker-options {
      border-color: #ccc;
    }
  }

  .ql-color-picker .ql-picker-item {
    &.ql-selected,
    &:hover {
      border-color: #000;
    }
  }

  + .ql-container.ql-snow {
    border-top: 0px;
  }
}

.ql-snow {
  .ql-tooltip {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px #ddd;
    color: #444;
    padding: 5px 12px;
    white-space: nowrap;

    &::before {
      content: "Visit URL:";
      line-height: 26px;
      margin-right: 8px;
    }

    input[type="text"] {
      display: none;
      border: 1px solid #ccc;
      font-size: 13px;
      height: 26px;
      margin: 0px;
      padding: 3px 5px;
      width: 170px;
    }

    a {
      &.ql-preview {
        display: inline-block;
        max-width: 200px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
      }

      &.ql-action::after {
        border-right: 1px solid #ccc;
        content: "Edit";
        margin-left: 16px;
        padding-right: 8px;
      }

      &.ql-remove::before {
        content: "Remove";
        margin-left: 8px;
      }

      line-height: 26px;
    }

    &.ql-editing {
      a {
        &.ql-preview,
        &.ql-remove {
          display: none;
        }
      }

      input[type="text"] {
        display: inline-block;
      }

      a.ql-action::after {
        border-right: 0px;
        content: "Save";
        padding-right: 0px;
      }
    }

    &[data-mode="link"]::before {
      content: "Enter link:";
    }

    &[data-mode="formula"]::before {
      content: "Enter formula:";
    }

    &[data-mode="video"]::before {
      content: "Enter video:";
    }
  }

  a {
    color: #06c;
  }
}

.ql-container.ql-snow {
  border: 1px solid #ccc;
}
// Set height
#quill {
  height: 200px;
}

/* Pages SelectFx */

/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  @include text-align(left);
  background: $color-contrast-lowest;
  width: 100%;
  max-width: 500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.cs-select:focus {
  outline: none; /* For better accessibility add a style for this in your skin */
}

.cs-select select {
  display: none;
}

.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Placeholder and selected option */
.cs-select > span {
  @include padding-right(3em);
}

.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cs-select > span::after {
  content: "\25BE";
  @include right(1em);
}

.cs-select .cs-selected span::after {
  content: "\2713";
  @include margin-left(1em);
}

.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: $color-contrast-lowest;
  visibility: hidden;
}

.cs-select.cs-active .cs-options {
  visibility: visible;
}

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.cs-select ul span {
  padding: 1em;
}

.cs-select ul li.cs-focus span {
  background-color: #ddd;
}

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  @include padding-left(1em);
}

.cs-select li.cs-optgroup > span {
  cursor: default;
}

div.cs-skin-slide {
  color: #fff;
  /*font-size: 1.5em;*/
  width: 300px;
}

@media screen and (max-width: 30em) {
  div.cs-skin-slide {
    font-size: 1em;
    width: 250px;
  }
}

div.cs-skin-slide::before {
  content: "";
  background: #282b30;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.cs-skin-slide.cs-active::before {
  -webkit-transform: scale3d(1.1, 3.5, 1);
  transform: scale3d(1.1, 3.5, 1);
}

.cs-skin-slide > span {
  height: 80px;
  line-height: 32px;
  -webkit-transition: text-indent 0.3s, opacity 0.3s;
  transition: text-indent 0.3s, opacity 0.3s;
}

@media screen and (max-width: 30em) {
  .cs-skin-slide > span {
    height: 60px;
    line-height: 28px;
  }
}

.cs-skin-slide.cs-active > span {
  text-indent: -290px;
  opacity: 0;
}

.cs-skin-slide.cs-active > span::after {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.cs-skin-slide .cs-options {
  background: transparent;
  width: 70%;
  height: 400%;
  padding: 1.9em 0;
  top: 50%;
  @include left(50%);
  @include translate3d(-50%, -50%, 0);
}

@media screen and (max-width: 30em) {
  .cs-skin-slide .cs-options {
    padding-top: 3em;
  }
}

.cs-skin-slide .cs-options li {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}

.cs-skin-slide.cs-active .cs-options li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.cs-skin-slide.cs-active .cs-options li:first-child {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
} /* more options need more delay declaration */

.cs-skin-slide .cs-options li span {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 65%;
  @include padding(0.8em, 1em, 0.8em, 2.5em);
}

.cs-skin-slide .cs-options li span:hover,
.cs-skin-slide .cs-options li.cs-focus span,
.cs-skin-slide .cs-options li.cs-selected span {
  color: #eb7e7f;
  background: transparent;
}

.cs-skin-slide .cs-selected span::after {
  content: "";
}

/* Pages Select  overriding */

.form-group-default .cs-skin-slide > span {
  @include padding(0, 30px, 0, 0);
  height: 22px;
  line-height: 21px;
}
.form-group-default .cs-wrapper {
  width: 100%;
  height: 100%;
  @include flex();
  @include align-items(center);
}
.cs-wrapper {
  display: inline-block;
}
.form-control.cs-select:not(.cs-active) {
  width: 100% !important;
}

.cs-select {
  background-color: transparent;
  span {
    text-overflow: initial;
  }
  .cs-placeholder {
    width: 100%;
  }
}
div.cs-skin-slide {
  width: auto;
  font-family: $interface-font-family, sans-serif;
  color: #5e5e5e;
  &:before {
    background-color: transparent;
  }
  &.cs-transparent {
    background: none;
    .cs-backdrop {
      border-color: transparent;
      background: none;
    }
    &.cs-active .cs-backdrop {
      background: $color-contrast-lowest;
    }
  }
  & > span {
    height: 35px;
    @include padding(6px, 33px, 6px, 17px);
    line-height: 23px;
    z-index: 1;
  }
  &.cs-active {
    z-index: $zIndex-navbar - 10;
    &:before {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    .cs-backdrop {
      border: transparent;
      background: $color-contrast-lowest;
      box-shadow: -1px 0 1px #ccc, 1px 0 1px #ccc;
    }
  }
  & > span:after,
  &.cs-active > span:after {
    content: "\e98b";
    font-family: "pages-icon";
    color: #5e5e5e;
    font-size: 23px;
    position: absolute;
    right: 7px;
  }
  .cs-options {
    height: auto;
    padding: 9px 0;
    width: auto;
    padding: 10px;
    max-height: 350px;
    transform: translate3d(1, 1, 1);
    overflow: hidden;
    z-index: 1;
    ul {
      width: 100%;
      display: table;
      li {
        display: table-row;
        span {
          display: table-cell;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: normal;
          padding: 5px 0;
          text-transform: none;
          max-height: 350px;
          overflow-y: auto;
        }
        span:hover,
        &.cs-focus span,
        &.cs-selected span {
          color: $color-contrast-higher;
        }
      }
    }
  }
}
.cs-backdrop {
  background: none repeat scroll 0 0 #fff;
  border: 1px solid $color-border-a;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  @include transition(all 0.3s ease);
}
.cs-skin-slide.cs-active .cs-options li:nth-child(6) {
  transition-delay: 0.3s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(7) {
  transition-delay: 0.35s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(8) {
  transition-delay: 0.4s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(9) {
  transition-delay: 0.45s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(10) {
  transition-delay: 0.5s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(11) {
  transition-delay: 0.55s;
}
.cs-input-group-addon {
  background: $color-contrast-lower;
  border: 1px solid $color-border-a;
  padding: 6px 10px;
}

// Twitter Typehead
// --------------------------------------------------
.twitter-typeahead {
  width: 100%;
}
.tt-menu {
  width: 100%;
  padding: 3px 10px 3px 3px;
  background-color: $color-contrast-lowest;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-top: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.tt-suggestion {
  color: $color-contrast-high;
  padding: 3px 7px 4px;
  padding-left: 26px;
  line-height: 24px;
  &:hover {
    cursor: pointer;
    background: $color-contrast-lower;
  }
  &.tt-cursor {
    background: $color-contrast-lower;
  }
  p {
    margin: 0;
  }
}
.form-group-default {
  &.typehead {
    overflow: inherit;
  }
  .tt-menu {
    width: calc(100% + 24px);
    margin: 4px -12px;
  }
}

.dropzone {
  overflow: hidden;
  .dz-default.dz-message {
    width: 100%;
  }
}

/* Dropzone for Angular */
.dropzone[dropzone="dropzone"] input[type="file"] {
  visibility: hidden;
}
/* end overrides */

/* Demo specifc classes */
.demo-form-wysiwyg {
  height: 250px;
}

// error form
.error-form {
  .form-group-default {
    padding: 0;
    justify-content: space-between;
    label {
      margin-top: 6px;
      padding-left: 12px;
    }
    .form-control {
      margin-top: -2px;
      margin-bottom: 3px;
      padding-left: 12px;
    }
  }
}
.error-container {
  &.bottom-container {
    margin-top: 0;
  }
}

.h-c-50 {
  height: calc(50px);
}
.card-toolbar {
  .note-editor {
    .btn-toolbar {
      display: block;
    }
  }
}
