/*------------------------------------------------------------------
[10. Modals]
*/
.modal {
  .close {
    position: absolute;
    right: 25px;
    top: 15px;
  }
  .close:focus {
    outline: 0;
  }
  .modal-dialog {
    transition: all 0.2s !important;
  }
  .modal-content {
    border: 1px solid #f2f6f7;
    border-radius: 3px;
    box-shadow: none;
  }
  .modal-header {
    text-align: center;
    border-bottom: 0;
    padding: 25px 25px 0 25px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    p {
      color: #8b91a0;
    }
  }
  .modal-body {
    box-shadow: none;
    padding: 25px;
    padding-top: 0;
    white-space: normal;
  }
  .modal-footer {
    border-top: none;
    box-shadow: none;
    margin-top: 0;
    padding: 25px;
    padding-top: 0;
  }
  .drop-shadow {
    box-shadow: 0 0 9px rgba(191, 191, 191, 0.36) !important;
  }
  &.fade {
    opacity: 1 !important;
    &.stick-up {
      .modal-dialog {
        @include translate3d(0, -100%, 0);
        margin-top: -5px;
      }
      /*&.in{
				.modal-dialog{
					@include translate3d(0,0,0);
				}	
			}*/
      &.show {
        .modal-dialog {
          @include translate3d(0, 0, 0);
        }
      }
    }

    &.slide-up {
      height: 100%;
      top: 0;
      &.show {
        .modal-dialog {
          @include translate3d(0, 0, 0);
        }
      }
      &.disable-scroll {
        overflow: hidden;
      }
      .modal-dialog {
        display: table;
        height: 100%;
        @include translate3d(0, 75%, 0);
      }
      .modal-content-wrapper {
        display: table-cell;
        vertical-align: middle;
      }
    }
    &.center-scale {
      .modal-dialog {
        display: table;
        height: 100%;
      }
      .modal-content {
        display: table-cell;
        vertical-align: middle;
        border: 0;
        background: transparent;
        .modal-header {
          border-bottom: 0px;
        }
      }
      /*&.in{
	  			.modal-dialog{
	  				opacity: 1;
					@include scale(1);
				}
			}*/
      &.show {
        .modal-dialog {
          opacity: 1;
          @include scale(1);
        }
      }
      .modal-dialog {
        opacity: 0;
        @include scale(0.6);
      }
    }
    &.fill-in {
      background-color: rgba(255, 255, 255, 0);
      @include transition(background-color 0.3s);
      & > .close {
        opacity: 0.6;
        z-index: 9999;
        & > i {
          font-size: 16px;
        }
      }
      .modal-dialog {
        display: table;
        height: 100%;
      }
      .modal-content {
        display: table-cell;
        vertical-align: middle;
        border: 0;
        background: transparent;
        .modal-header {
          border-bottom: 0px;
        }
      }
      /*&.in{
				background-color: rgba(255, 255, 255, 0.85);
	  			.modal-dialog{
	  				opacity: 1;
					@include scale(1);
				}
			}*/
      &.show {
        background-color: rgba(255, 255, 255, 0.85);
        .modal-dialog {
          opacity: 1;
          @include scale(1);
        }
      }
      .modal-dialog {
        opacity: 0;
        @include scale(0.6);
      }
    }
    &.slide-right {
      .close {
        position: absolute;
        top: 0;
        @include right(0);
        @include margin-right(10px);
        z-index: 10;
      }
      /*&.in{
	  			.modal-dialog{
					@include translate3d(0,0,0);
				}
			}*/
      &.show {
        .modal-dialog {
          @include translate3d(0, 0, 0);
        }
      }
      .modal-dialog {
        position: absolute;
        @include right(0);
        margin: 0;
        height: 100%;
        @include translate3d(100%, 0, 0);
        .modal-content-wrapper {
          height: 100%;
        }
        .modal-content {
          height: 100%;
        }
        .modal-body {
          background-color: $color-contrast-lowest;
        }
      }
      .modal-content {
        border-radius: 0px;
      }
    }
  }
}
.fill-in-modal {
  .modal-backdrop {
    background-color: transparent;
  }
}
.modal-backdrop {
  opacity: 0;
  @include transition(opacity 0.2s linear);
  /*&.in{
		opacity: 0.30;
	}*/
  &.show {
    opacity: 0.3;
  }
}

/* Responsive Handlers: Modals
------------------------------------
*/
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
  .modal-xlg {
    width: 1200px !important;
    max-width: 1200px;
  }
}

@media (max-width: 768px) {
  .modal {
    &.fill-in {
      .modal-dialog {
        width: calc(100% - 20px);
      }
    }
    &.slide-up {
      .modal-dialog {
        width: calc(100% - 20px);
      }
    }
  }
}
@media (min-width: 768px) {
  .modal {
    &.stick-up {
      .modal-dialog {
        margin: -5px auto;
      }
    }
    &.slide-up {
      .modal-dialog {
        margin: 0 auto;
      }
    }
    &.fill-in {
      .modal-dialog {
        margin: 0 auto;
      }
    }
    .modal-content {
      box-shadow: none;
    }
    .modal-dialog {
      width: 800px;
    }
    .modal-sm {
      width: 300px;
    }
    .modal-sm {
      min-width: auto;
    }
  }
}
