/*------------------------------------------------------------------
Pages Icons
------------------------------------------------------------------ */

@font-face {
  font-family: "pages-icon";
  src: url("../../fonts/pages-icon/pages-icon.woff") format("woff"),
    url("../../fonts/pages-icon/pages-icon.ttf") format("truetype"),
    url("../../fonts/pages-icon/pages-icon.svg") format("svg");
  font-weight: normal;
	font-style: normal;
	font-display: block;
}

.pg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "pages-icon" !important;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  direction: ltr;
  word-wrap: normal;
  white-space: nowrap;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
