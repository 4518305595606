.visible-xlg {
  display: none;
}
.hidden-xlg {
  display: block;
}

/*** Large screens ***/
@media (min-width: 1824px) {
  .visible-xlg {
    display: block !important;
  }
  .hidden-xlg {
    display: none !important;
  }

  .quickview-wrapper .tab-content #quickview-notes > .inner {
    width: 570px;
  }

  .container-fluid {
    &.container-fixed-lg {
      width: $layout-container-fixed-lg;
      margin-right: auto;
      margin-left: auto;
    }
  }
  .menu-pin .container-fluid.container-fixed-lg {
    width: $layout-container-fixed-lg - $layout-sidepanel-pin-width;
  }
  /*.visible-xlg {
        display: block !important;
    }
    .hidden-xlg {
        display: none !important;
    }*/

  .col-xlg-1,
  .col-xlg-2,
  .col-xlg-3,
  .col-xlg-4,
  .col-xlg-5,
  .col-xlg-6,
  .col-xlg-7,
  .col-xlg-8,
  .col-xlg-9,
  .col-xlg-10,
  .col-xlg-11 {
    // float: left;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }
  .col-xlg-12 {
    // float: left;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    // width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xlg-11 {
    // width: 91.6667%;
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }
  .col-xlg-10 {
    width: 83.3333%;
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }
  .col-xlg-9 {
    // width: 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xlg-8 {
    // width: 66.6667%;
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }
  .col-xlg-7 {
    // width: 58.3333%;
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }
  .col-xlg-6 {
    // width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xlg-5 {
    // width: 41.6667%;
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }
  .col-xlg-4 {
    // width: 33.3333%;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
  .col-xlg-3 {
    // width: 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xlg-2 {
    // width: 16.6667%;
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }
  .col-xlg-1 {
    // width: 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xlg-pull-12 {
    right: 100%;
  }
  .col-xlg-pull-11 {
    right: 91.6667%;
  }
  .col-xlg-pull-10 {
    right: 83.3333%;
  }
  .col-xlg-pull-9 {
    right: 75%;
  }
  .col-xlg-pull-8 {
    right: 66.6667%;
  }
  .col-xlg-pull-7 {
    right: 58.3333%;
  }
  .col-xlg-pull-6 {
    right: 50%;
  }
  .col-xlg-pull-5 {
    right: 41.6667%;
  }
  .col-xlg-pull-4 {
    right: 33.3333%;
  }
  .col-xlg-pull-3 {
    right: 25%;
  }
  .col-xlg-pull-2 {
    right: 16.6667%;
  }
  .col-xlg-pull-1 {
    right: 8.33333%;
  }
  .col-xlg-pull-0 {
    right: 0;
  }
  .col-xlg-push-12 {
    left: 100%;
  }
  .col-xlg-push-11 {
    left: 91.6667%;
  }
  .col-xlg-push-10 {
    left: 83.3333%;
  }
  .col-xlg-push-9 {
    left: 75%;
  }
  .col-xlg-push-8 {
    left: 66.6667%;
  }
  .col-xlg-push-7 {
    left: 58.3333%;
  }
  .col-xlg-push-6 {
    left: 50%;
  }
  .col-xlg-push-5 {
    left: 41.6667%;
  }
  .col-xlg-push-4 {
    left: 33.3333%;
  }
  .col-xlg-push-3 {
    left: 25%;
  }
  .col-xlg-push-2 {
    left: 16.6667%;
  }
  .col-xlg-push-1 {
    left: 8.33333%;
  }
  .col-xlg-push-0 {
    left: 0;
  }
  .col-xlg-offset-12 {
    margin-left: 100%;
  }
  .col-xlg-offset-11 {
    margin-left: 91.6667%;
  }
  .col-xlg-offset-10 {
    margin-left: 83.3333%;
  }
  .col-xlg-offset-9 {
    margin-left: 75%;
  }
  .col-xlg-offset-8 {
    margin-left: 66.6667%;
  }
  .col-xlg-offset-7 {
    margin-left: 58.3333%;
  }
  .col-xlg-offset-6 {
    margin-left: 50%;
  }
  .col-xlg-offset-5 {
    margin-left: 41.6667%;
  }
  .col-xlg-offset-4 {
    margin-left: 33.3333%;
  }
  .col-xlg-offset-3 {
    margin-left: 25%;
  }
  .col-xlg-offset-2 {
    margin-left: 16.6667%;
  }
  .col-xlg-offset-1 {
    margin-left: 8.33333%;
  }
  .col-xlg-offset-0 {
    margin-left: 0;
  }
}
/*** Desktops ***/
@media (min-width: 1600px) {
}
/*** Medium Size Screen ***/
@media only screen and (max-width: 1400px) {
  .page-sidebar .page-sidebar-inner .sidebar-slide {
    .sidebar-menu {
      bottom: 50px;
    }
    .sidebar-widgets {
      display: none;
    }
  }
  .footer-widget {
    padding: 11px 21px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .visible-xl-down {
    display: block !important;
  }
}

/*** Desktops & Laptops ***/
@media only screen and (min-width: 980px) {
  body {
    &.ie9.menu-pin {
      .page-sidebar {
        transform: none !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
      }
    }
    &.menu-pin {
      .header .brand {
        width: 245px;
      }
      .page-container {
        padding-left: 0;
        .page-content-wrapper {
          .content {
            padding-left: $layout-sidepanel-pin-width;
          }
          .footer {
            left: $layout-sidepanel-pin-width;
          }
        }
      }
      [data-toggle-pin="sidebar"] {
        & > i:before {
          content: "\e9a2";
        }
      }
      .page-sidebar {
        transform: translate(
          $layout-sidepanel-width - $layout-sidepanel-width-collapsed,
          0
        ) !important;
        -webkit-transform: translate(
          $layout-sidepanel-width - $layout-sidepanel-width-collapsed,
          0
        ) !important;
        -ms-transform: translate(
          $layout-sidepanel-width - $layout-sidepanel-width-collapsed,
          0
        ) !important;
        width: $layout-sidepanel-pin-width;
        .sidebar-header .sidebar-header-controls {
          transform: translateX(21px);
          -webkit-transform: translateX(21px);
        }
        .menu-items .icon-thumbnail {
          transform: translate3d(-14px, 0, 0);
          -webkit-transform: -webkit-translate3d(-14px, 0, 0);
        }
      }
    }
    &.menu-behind {
      .page-sidebar {
        z-index: $zIndex-navbar - 1;
      }
      .header .brand {
        width: 200px;
        text-align: left;
        padding-left: 20px;
      }
    }
    &.box-layout {
      background-color: $color-contrast-lowest;
      & > .container,
      & > .full-height > .container {
        height: 100%;
        padding: 0;
        background-color: $color-contrast-lowest;
      }
      .header {
        background-color: transparent;
        border: 0;
        padding: 0;
        & > .container {
          background-color: $color-contrast-lowest;
          border-bottom: 1px solid rgba(230, 230, 230, 0.7);
          padding: 0 20px 0 0;
          height: 100%;
        }
      }
      .page-sidebar {
        left: auto;
        transform: none !important;
        -webkit-transform: none !important;
      }
      .page-container .page-content-wrapper .footer {
        width: auto;
      }
    }
  }

  .header {
    .brand {
      position: relative;
    }
    .user-info-wrapper {
      .user-details {
        .user-name {
          font-size: 16px;
        }
        .user-other {
          font-size: 10px;
        }
      }
      .user-pic {
        position: relative;
        top: -6px;
      }
    }
  }

  .notification-panel {
    width: 400px;
  }
}
/*** General Small Screen Desktops ***/

/*** General tablets and phones ***/
@media (max-width: 991px) {
  .hidden-lg-down {
    display: none !important;
  }

  .page-container {
    padding-left: 0;
  }

  .d-flex-lg-down {
    display: flex;
  }
  .justify-between-lg-down {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  body {
    &.sidebar-open {
      .page-container {
        @include translate3d(250px, 0, 0);
        overflow: hidden;
        position: fixed;
      }
      .push-on-sidebar-open {
        @include translate3d(250px, 0, 0);
        overflow: hidden;
      }
    }
    &.box-layout {
      & > .container {
        padding: 0;
        height: 100%;
      }
      .header {
        & > .container {
          padding: 0;
          .pull-right .sm-action-bar {
            right: 0;
          }
        }
      }
    }
    &.horizontal-app-menu {
      .header {
        & > .container {
          padding: 0;
        }
      }
      .secondary-sidebar {
        margin-left: 0;
      }
    }
  }
  .sm-action-bar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    & > a {
      padding: 10px;
      display: inline-block;
    }
  }
  .pace .pace-activity {
    top: 60px;
  }
  .page-sidebar {
    width: 250px;
    z-index: auto;
    left: 0 !important;
    -webkit-transform: translate3d(0, 0px, 0px) !important;
    transform: translate3d(0, 0px, 0px) !important;
    .sidebar-menu .menu-items > li ul.sub-menu > li {
      padding: 0px 30px 0 36px;
    }
  }

  .page-container {
    padding-left: 0;
    // z-index: 100;
    position: relative;
    transition: transform 0.25s ease;
    -webkit-transition: -webkit-transform 0.25s ease;
    .page-content-wrapper .content {
      overflow-x: hidden;
      height: auto; // minimizes address bar on scroll
    }
  }

  .icon-thumbnail {
    margin-right: 24px;
  }
  .page-sidebar {
    display: none;
    z-index: auto;
    &.visible {
      display: block;
    }
    .page-sidebar-inner {
      z-index: 1;
      left: 0 !important;
      width: 260px;
      .sidebar-slide {
        .sidebar-menu {
          overflow: scroll;
          -webkit-overflow-scrolling: touch;
          top: 40px;
          bottom: 0;
          ul > li > a > .badge {
            display: inline-block;
          }
        }
      }
    }
  }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -125px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px;
    .btn-compose {
      display: none;
    }
  }
  .inner-content,
  .horizontal-app-menu .inner-content {
    margin: 0;
  }
  .breadcrumb {
    padding-left: 15px;
    padding-right: 15px;
  }
  .copyright {
    padding-left: 15px;
    padding-right: 15px;
  }

  // Pages Notification customizations
  body > .pgn-wrapper[data-position="top"] {
    top: $layout-mobile-header-height;
    left: 0;
  }
  body > .pgn-wrapper[data-position="bottom"] {
    left: 0;
  }

  body > .pgn-wrapper[data-position$="-left"] {
    left: 20px;
    right: auto;
  }

  .sm-table {
    display: table;
    width: 100%;
  }
  .user-profile-wrapper {
    position: absolute;
    right: 50px;
    top: -9px;
  }

  .container {
    width: 100%;
  }

  //Table Block
  .table-responsive-block {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    tr {
      border: 1px solid rgba(230, 230, 230, 0.7);
      margin-bottom: 15px;
    }
    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      white-space: normal;
      text-align: left;
      &:last-child {
        border-bottom: 0;
      }
      &:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
      }
      &:before {
        content: attr(data-title);
      }
    }
  }
  .kill-pull-md-down {
    position: relative;
    display: block;
    width: inherit;
    .toggle-secondary-sidebar {
      display: inline-block;
      font-size: 18px;
    }
  }

  //set a min hieght for dropdown tabs
  .nav-tabs-header.nav-tabs-linetriangle {
    height: 61px;
  }

  .overlay {
    padding-left: 15px;
  }
}

/* Landscape view of all tablet devices */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .page-sidebar .sidebar-menu .menu-items ul {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }
  .page-container {
    .page-content-wrapper {
      .content {
        padding-top: $layout-header-height;
      }
    }
  }
  body.box-layout > .container {
    width: 100%;
  }
  .inner-content {
    margin-left: 0;
  }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -155px;
		padding: 20px;
		padding-left: 0;
    position: absolute;
    right: 0;
    top: $layout-header-height;
    z-index: 9999;
    display: none;
		border-radius: 10px;
		box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
		rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
    .btn-compose {
      display: none;
    }
	}
	//email icon
	.mark-email{
		margin-left: 25px;
	}
  .horizontal-app-menu .secondary-sidebar {
    display: inline-block;
    position: relative;
    top: inherit;
    left: inherit;
    right: inherit;
    padding: 20px 0;
    border-radius: 0;
    float: left;
    height: 100%;
  }
  .split-details {
    margin-left: 0;
  }
  .toggle-secondary-sidebar {
    font-size: 18px;
    display: block;
    font-size: 18px;
    left: 50%;
    margin-left: -36px;
    position: absolute;
    & ~ .brand {
      display: none !important;
    }
  }
}

// Resolution specific borders
@media (max-width: 991px) {
  .sm-b-r,
  .sm-b-l,
  .sm-b-t,
  .sm-b-b {
    border-width: 0;
  }
  .sm-b-r {
    border-right-width: 1px;
  }
  .sm-b-l {
    border-left-width: 1px;
  }
  .sm-b-t {
    border-top-width: 1px;
  }
  .sm-b-b {
    border-bottom-width: 1px;
  }
  .sidebar-menu {
    .icon-thumbnail {
      margin-right: 15px;
    }
  }
}

// Make all columns equal height
// (Not applied for resolutions lowers than mentioned ex:lg,md,sm
// because they stack up)
@media (min-width: 1200px) {
  .row-same-height {
    overflow: hidden;
    & > [class*="col-lg"] {
      margin-bottom: -99999px;
      padding-bottom: 99999px;
    }
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  //@TODO : Old Media Query current BS4 doesnt not allow this.
  .visible-md {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .row-same-height {
    overflow: hidden;
    & > [class*="col-md"] {
      margin-bottom: -99999px;
      padding-bottom: 99999px;
    }
  }
}

@media (min-width: 768px) {
  .row-same-height {
    overflow: hidden;
    & > [class*="col-sm"] {
      margin-bottom: -99999px;
      padding-bottom: 99999px;
    }
  }
  .box-layout .container .jumbotron,
  .container-fluid .jumbotron {
    padding: 0;
  }
  .d-flex-md-up {
    display: flex;
  }

  .navbar-toggle {
    display: none;
  }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .dataTables_wrapper.form-inline .checkbox input[type="checkbox"],
  .form-inline .radio input[type="radio"] {
    position: absolute;
  }
  .justify-end-md-up {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media (min-width: 700px) {
  .dd {
    float: none;
  }
}

/* Portrait view of all tablet devices */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .page-sidebar .sidebar-menu .menu-items > li {
    & > a {
      padding-left: 27px;
    }
    ul.sub-menu > li {
      padding: 0px 27px 0 31px;
    }
  }

  .icon-thumbnail {
    margin-right: 21px;
  }
}

//MD Screen
@media (max-width: 991px) and (min-width: 768px) {
  //OLD BS classes Remove later
  .visible-sm {
    display: block;
  }
  .hidden-sm {
    display: none;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
  //New Screen Prefix padding and margin
  @include generate-paddings-options-prefix("md", 3);
  @include generate-paddings-options-prefix("md", 3);
}

@media (max-width: 979px) {
  .toggle-secondary-sidebar {
    font-size: 18px;
    position: static;
    display: block;
    & ~ .brand {
      display: none !important;
    }
  }

  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -125px;
		padding: 20px;
		padding-left: 0;
    position: absolute;
    right: 0;
    top: $layout-header-height;
    z-index: 9999;
    display: none;
		border-radius: 10px;
		box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
        rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
    .btn-compose {
      display: none;
    }
  }

  .split-view {
    .split-list {
      width: 100%;
      height: auto;
      bottom: 50px;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 1000;
      float: none;
      .item {
        padding: 8px;
        .inline {
          width: 188px;
        }
      }
    }

    .split-details {
      margin-left: 0;
      width: 100%;

      .email-content-wrapper {
        overflow: auto;
        padding: 0;
        height: calc(100% - 50px);
        .email-content {
          width: 90%;
        }
      }
    }
  }

  .compose-wrapper {
    display: block !important;
    .btn-compose {
      display: block !important;
    }
  }
}

@media (max-width: 767px) {
  .jumbotron,
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .page-container .page-content-wrapper .content {
    padding-top: $layout-mobile-header-height;
    padding-bottom: 100px;
  }
  .page-sidebar .sidebar-header {
    padding: 0 12px;
  }

  .page-sidebar .sidebar-menu .menu-items > li {
    & > a {
      padding-left: 20px;
    }
    ul.sub-menu > li {
      padding: 0px 25px 0 28px;
    }
  }

  .icon-thumbnail {
    margin-right: 20px;
  }
  .secondary-sidebar {
    top: $layout-mobile-header-height;
  }
  .split-details {
    margin-left: 0;
  }
  .email-composer {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sm-pull-bottom,
  .sm-pull-up {
    position: relative !important;
  }

  //Generate Margin and Paddings.
  @include generate-paddings-options-prefix("sm", 10);
  @include generate-paddings-options-prefix("sm", 10);

  .sm-no-margin {
    margin: 0px;
  }
  .sm-no-padding {
    padding: 0px;
  }
  .sm-text-right {
    text-align: right !important;
  }
  .sm-text-left {
    text-align: left !important;
  }
  .sm-text-center {
    text-align: center !important;
  }
  .sm-pull-right {
    float: right !important;
  }
  .sm-pull-left {
    float: left !important;
  }
  .sm-pull-reset {
    float: none !important;
  }
  .sm-block {
    display: block;
  }

  .sm-image-responsive-height {
    width: 100%;
    height: auto;
  }
  .email-composer {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sm-padding-10 {
    padding: 10px !important;
  }

  .lock-box {
    align-items: flex-start;
    height: auto;
  }
  .login-box {
    padding-right: 15px;
    padding-left: 15px;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }

  .sm-padding-10 {
    padding: 10px !important;
  }

  .lock-box {
    align-items: flex-start;
    height: auto;
  }
  .login-box {
    padding-right: 15px;
    padding-left: 15px;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }

  .nav-tabs-linetriangle {
    &.nav-cust {
      flex-direction: column;
    }
    li {
      a.active:after,
      a.active:before {
        display: none;
      }
    }
  }

  //View Port Heights
  .sm-vh-25 {
    height: 25vh;
  }
  .sm-vh-50 {
    height: 50vh;
  }
  .sm-vh-75 {
    height: 75vh;
  }
  .sm-vh-100 {
    height: 100vh;
  }

  .horizontal-app-menu {
    .page-container .page-content-wrapper .content > .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  //Summernote btn-group wrap
  .summernote-wrapper .btn-group {
    -ms-flex-flow: wrap;
    flex-flow: wrap;
  }
}

/*** Phones ***/
@media (max-width: 480px) {
  body {
    width: 100%;
    #overlay-search {
      font-size: 48px;
      height: 118px;
      line-height: 46px;
    }
  }
  .page-sidebar .sidebar-header {
    height: $layout-mobile-header-height;
    line-height: $layout-mobile-header-height;
  }
  .panel {
    .panel-heading {
      padding-left: 15px;
    }
    .panel-body {
      padding: 15px;
      padding-top: 0;
    }
  }
  .map-controls {
    left: 10px;
  }
  .register-container {
    height: auto !important;
  }
}

/*** Retina Display Images **/
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 200/100),
  only screen and (min-device-pixel-ratio: 2) {
  // .editor-icon {
  //   background-image: url("#{$base-img-url}/editor_tray_2x.png");
  //   background-size: 480px 40px;
  // }
  .alert .close {
    background: url("#{$base-img-url}/icons/noti-cross-2x.png") no-repeat scroll
      0 0 transparent;
    background-position: -9px -10px;
    width: 10px;
    height: 9px;
    position: relative;
    opacity: 0.8;
    background-size: 114px 29px;
  }
}

.hidden {
  display: none !important;
}
.visible-sm,
.visible-xs {
  display: none;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block;
  }
  .hidden-xs {
    display: none;
  }
}
